import React from "react";
import "./closebutton.scss";

const CloseButton = ({ cname }) => {
  return (
    <div className={cname} id="close-item">
      <a href="#!" className="sidenav-close">
        <i className="material-icons" id="item">
          close
        </i>
      </a>
    </div>
  );
};

export default CloseButton;
