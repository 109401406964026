import React from "react";
import "./image.scss";
import { Col } from "react-materialize";
import { useTranslation } from "react-i18next";

const Image = ({ image }) => {
  const { t } = useTranslation(["about"]);
  let img = (
    <i className={`material-icons ${image.cname}`} id="item">
      {image.icon}
    </i>
  );
  if (image.src) {
    img = (
      <img
        className={image.cname}
        src={`${process.env.PUBLIC_URL}${image.src}`}
        alt=""
      />
    );
  }
  return (
    <>
      <Col s={image.s} l={image.l} m={image.m} className={image.crow}>
        {img}
        {!image.text ? (
          <div></div>
        ) : (
          <p className={`image-info ${image.ctext}`}>{t(image.text)}</p>
        )}
      </Col>
    </>
  );
};

export default Image;
