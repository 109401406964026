import React, { useState, useEffect, Suspense } from "react";
import "./blogDetail.scss";
import RecentPost from "../../components/RecentPost/RecentPost";
import InfoTag from "../../components/Tag/InfoTag";
import SearchTag from "../../components/Tag/SearchTag";
import { getblogdetail } from "../../client/pages/blogdetail";
import { Row, Col } from "react-materialize";
import { getblogs, getpopulartags } from "../../client/pages/blog";
import { useTranslation } from "react-i18next";
import { Redirect,navigate } from "@reach/router";
import LoadingSpinner from "../../components/SemipolarLoading";

const BlogDetail = (props) => {
  const [tag, setTag] = useState("");
  const [blog, setBlog] = useState({});
  const [image, setImage] = useState("");
  const [tags, setTags] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [popularTags, setPopularTags] = useState([]);
  const [publishDate, setDate] = useState(new Date());
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const { t, i18n } = useTranslation(["blogdetail", "date"]);
  const changeTag = (selectedTag) => {
    if (tag == selectedTag) setTag("");
    else setTag(selectedTag);
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "/images/logos/logotipoOficial.svg";
  };
  useEffect(() => {
    getblogs(1, 3)
      .then((blogs) => {
        setRecentPosts(blogs.data.entries);
      })
      .catch(() => {
        setRecentPosts([]);
      });
  }, [i18n.language]);
  useEffect(() => {
    getpopulartags(10)
      .then((pTags) => {
        setPopularTags(pTags.data);
      })
      .catch(() => {
        setPopularTags([]);
      });
  }, [i18n.language]);
  useEffect(() => {
    getblogdetail(props.id)
      .then((blogJson) => {
        setBlog(blogJson.data);
        setImage(blogJson.data.imageURL);
        setTags(blogJson.data.tags);
        setDate(new Date(blogJson.data.publishDate));
        setAuthor(blogJson.data.author);
        setTitle(blogJson.data.title);
      })
      .catch(() => {
        navigate('/404');
      });
  }, [i18n.language, props.id]);
  const edjsParser = require("editorjs-parser").default;
  const config = {
    image: {
      use: "figure",
      imgClass: "blog-img responsive-img", // used class for img tags
      figureClass: "blog-fig", // used class for figure tags
      figCapClass: "fig-cap", // used class for figcaption tags
      path: "absolute",
    },
    paragraph: {
      pClass: "blog-paragraph", // used class for paragraph tags
    },
    code: {
      codeBlockClass: "code-block", // used class for code blocks
    },
    embed: {
      useProvidedLength: false,
    },
    quote: {
      applyAlignment: false,
    },
  };
  const parser = new edjsParser(config, undefined, undefined);
  const parsehtml = require("html-react-parser");
  let markup;
  if (Object.keys(blog).length != 0) {
    if (blog.content == null) {
      return <Redirect noThrow to="/404" />
    }
    markup = parser.parse(blog.content);
  } else {
    markup = "<div></div>";
  }
  const html = parsehtml(markup);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div className="blog-details">
        <div className="blog-cover">
          <Row>
            <Col l={4} className="text-inside-cover offset-l2">
              <p>{t("title-head")}</p>
              <h3>{t("title")}</h3>
              <p>{t("title-low")}</p>
            </Col>
            <Col
              l={3}
              className="img-inside-cover offset-l1 hide-on-med-and-down"
            >
              <img
                className="blog-cover-img"
                src={`${process.env.PUBLIC_URL}/images/blogdetail/blog_header.svg`}
                alt=""
              />
            </Col>
          </Row>
        </div>
        <Row className="blog-section">
          <Col l={5} s={12} className="offset-l2">
            <img
              className="blog-principal-img responsive-img"
              src={image}
              alt=""
              onError={addDefaultSrc}
            />
            <h4>{title}</h4>
            <Row className="blog-info">
              <Col s={4} className="date">
                <i className="material-icons">date_range</i>
                {t("date:dateP", {
                  day: publishDate.getDate(),
                  month: publishDate.getMonth(),
                  year: publishDate.getFullYear(),
                })}
              </Col>
              <Col s={8} className="author">
                {author}
              </Col>
            </Row>
            <div className="blog-details-content">{html}</div>
            <div className="blog-options">
              <Row className="tag-blog">
                Tags:
                {tags.map((item) => (
                  <InfoTag key={item.id} name={item.name} />
                ))}
              </Row>
            </div>
          </Col>
          <Col l={3} s={12} className="offset-l0">
            {recentPosts.length === 0 ? (
              <div></div>
            ) : (
              <RecentPost posts={recentPosts} />
            )}
            {popularTags.length === 0 ? (
              <div></div>
            ) : (
              <div className="tag-search">
                <Row>
                  <h5>{t("popular-tags")}</h5>
                </Row>
                {popularTags.map((item, index) => (
                  <SearchTag
                    key={index}
                    name={item.name}
                    handleTag={changeTag}
                    selectedTag={tag}
                  />
                ))}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Suspense>
  );
};

export default BlogDetail;
