// key, title, img, content
export const information = [
  {
    title: "about-title",
    content: "about-content",
  },
  {
    title: "values-title",
    content: "values-content",
  },
  {
    title: "objective-title",
    content: "objective-content",
  },
  {
    title: "justification-title",
    content1: "justification-content1",
    content2: "justification-content2",
    content3: "justification-content3",
  },
  {
    title: "partners-title",
    content: "partners-content",
  },
];

export const values = [
  {
    s: 3,
    m: 4,
    l: 4,
    crow: "offset-s2",
    cname: "image-values",
    text: "collaboration",
    icon: "people",
  },
  {
    s: 3,
    m: 4,
    l: 4,
    crow: "offset-s2 offset-l4",
    cname: "image-values",
    text: "integrity",
    icon: "fingerprint",
  },
  {
    s: 6,
    m: 4,
    l: 4,
    crow: "offset-s3 offset-l4",
    cname: "image-values",
    text: "excellence",
    icon: "directions_walk",
  },
];

export const partners = [
  {
    s: 4,
    m: 6,
    l: 2,
    crow: "offset-s4 offset-l2",
    cname: "image-partner responsive-img ",
    src: "/images/about/partners/roche.png",
  },
  {
    s: 4,
    m: 6,
    l: 2,
    crow: "offset-s4 offset-l1",
    cname: "image-partner responsive-img ",
    src: "/images/about/partners/uav.png",
  },
  {
    s: 4,
    m: 6,
    l: 2,
    crow: "offset-s4 offset-l1",
    cname: "image-partner responsive-img ",
    src: "/images/about/partners/utec.png",
  },
];
