import React from "react";
import "./socialnetworks.scss";
import "./style.css";

const SocialNetworks = ({ social }) => {
  return (
    <div id="redes" className="social-container">
      {!social || !social.length ? (
        <div></div>
      ) : (
        social.map((red, index) => {
          return (
            <a target='_blank' rel='noopener noreferrer' href={red.link} key={index}>
              <span id="red" key={index} className={`icon-${red.name}`} />
            </a>
          );
        })
      )}
    </div>
  );
};

export default SocialNetworks;
