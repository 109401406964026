import React from "react";
import "./tag.scss";

const SearchTag = (props) => {
  return (
    <>
      <a href="#!" onClick={() => props.handleTag(props.name)}>
        <div
          className={
            props.selectedTag == props.name
              ? `info-tag selected-tag`
              : `info-tag unselected-tag`
          }
        >
          {props.name}
        </div>
      </a>
    </>
  );
};

export default SearchTag;
