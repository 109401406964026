import React, { Component } from "react";
// import Particles from "react-particles";
// import particlesConfig from "./particles-config";
import { loadFull } from "tsparticles";

class Background extends Component {
  particlesInit = async (main) => {
    await loadFull(main);
  };

  render() {
    return (
      // <Particles
      //   // height="840px"
      //   // className="responsive-img"
      //   init={particlesConfig}
      // />
      <div style={{ height: 840}}>
        {/* <Particles
          // id="tsparticles"
          style={{marginTop: 40, height: 100}}
          // className="responsive-img"
          height="840px"
          init={this.particlesInit}
          options={particlesConfig}
          fullScreen={{enable: false, zIndex: 1}}
        /> */}
      </div>
    );
  }
}

export default Background;
