import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getblogs, getpopulartags } from "../../client/pages/blog";
import SearchTag from "../../components/Tag/SearchTag";
import "./blog.scss";
import { Pagination, Icon, Row, Col, CardPanel } from "react-materialize";
import Blogcard from "../../components/Blogcard/Blogcard";

const Blog = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalpages, setTotalPages] = useState(1);
  const [per_page] = useState(5);
  const [popularTags, setPopularTags] = useState([]);
  const { t, i18n } = useTranslation(["blogdetail", "date"]);
  const [entries, setEntries] = useState([]);
  const [tag, setTag] = useState("");
  const changeTag = (selectedTag) => {
    if (tag == selectedTag) setTag("");
    else setTag(selectedTag);
  };
  useEffect(() => {
    getpopulartags(10)
      .then((pTags) => {
        setPopularTags(pTags.data);
      })
      .catch(() => {
        setPopularTags([]);
      });
  }, [i18n.language]);
  useEffect(() => {
    setLoading(true);
    getblogs(page, per_page)
      .then((blogs) => {
        setTotalPages(blogs.data.total_pages);
        setEntries(blogs.data.entries);
      })
      .catch(() => {
        setPage(1);
        setTotalPages(1);
        setEntries([]);
      });
    setLoading(false);
  }, [i18n.language, page, per_page]);
  const changePage = (number) => setPage(number);
  return (
    <div>
      <div className="blog-cover">
        <Row>
          <Col l={4} className="text-inside-cover offset-l2">
            <p>{t("title-head")}</p>
            <h3>{t("title")}</h3>
            <p>{t("title-low")}</p>
          </Col>
          <Col
            l={3}
            className="img-inside-cover offset-l1 hide-on-med-and-down"
          >
            <img
              className="blog-cover-img"
              src={`${process.env.PUBLIC_URL}/images/blogdetail/blog_header.svg`}
              alt=""
            />
          </Col>
        </Row>
      </div>
      <Row className="blog-section">
        <Col l={5} m={10} s={12} className="offset-l2 offset-m1">
          {entries.length === 0 ? (
            <CardPanel className="white no-results">
              <span className="black-text">{t("no-results")}</span>
            </CardPanel>
          ) : loading ? (
            <h2>{t("loading")}...</h2>
          ) : (
            entries.map((item) => (
              <Blogcard
                key={item.id}
                id={item.id}
                image={item.imageURL}
                title={item.title}
                date={new Date(item.publishDate)}
                author={item.author}
                summary={item.summary}
              />
            ))
          )}
          <Pagination
            classname="pagination"
            activePage={1}
            items={totalpages}
            leftBtn={<Icon>chevron_left</Icon>}
            maxButtons={8}
            rightBtn={<Icon>chevron_right</Icon>}
            onSelect={changePage}
          />
        </Col>
        <Col l={3} s={12} className="offset-l0">
          {popularTags.length === 0 ? (
            <div></div>
          ) : (
            <div className="tag-search">
              <Row>
                <h5>{t("popular-tags")}</h5>
              </Row>
              {popularTags.map((item, index) => (
                <SearchTag
                  key={index}
                  name={item.name}
                  handleTag={changeTag}
                  selectedTag={tag}
                />
              ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Blog;
