import React from "react";
import "./recentpost.scss";
import { Row, Col } from "react-materialize";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";

const RecentPost = ({ posts }) => {
  const { t } = useTranslation(["blogdetail"]);
  const addDefaultSrc = (ev) => {
    ev.target.src = "/images/blogdetail/blog-default.jpg";
  };
  return (
    <div className="options-border">
      <Row>
        <h5>{t("recent-posts")}</h5>
      </Row>
      {posts.map((item, index) => {
        return (
          <Link key={index} to={`/blog/${item.id}`}>
            <Row>
              <div className="small-post">
                <Col>
                  <img
                    className="post-img"
                    src={item.imageURL}
                    key={index}
                    alt=""
                    onError={addDefaultSrc}
                  />
                </Col>
                <Col l={8}>
                  <Row>
                    <h6>{"title" in item ? item.title : ""}</h6>
                  </Row>
                  <Row>
                    <p>{"summary" in item ? item.summary : ""}</p>
                  </Row>
                </Col>
              </div>
            </Row>
          </Link>
        );
      })}
    </div>
  );
};

export default RecentPost;
