import React from "react";
import "./researcher.scss";
import SocialNetworks from "./SocialNetworks/SocialNetworks";
import { CollapsibleItem } from "react-materialize";

const Researcher = (props) => {
  return (
    <CollapsibleItem
      expanded={false}
      header={
        <div>
          <span className="title" id="researcherTitle">
            {props.name}
          </span>
          <p id="researcherParagraph">
            {props.position}
            <br />
          </p>
        </div>
      }
      icon={
        !props.img ? (
          <div></div>
        ) : (
          <img
            id="researcherImg"
            alt=""
            className="circle z-depth-2 responsive-img"
            src={props.img}
          />
        )
      }
      node="div"
      onSelect={(f) => f}
    >
      {props.biography}
      <SocialNetworks social={props.social} />
    </CollapsibleItem>
  );
};

export default Researcher;
