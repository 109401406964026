import React from "react";
import "./blogcard.scss";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-materialize";
import { Link } from "@reach/router";
const Blogcard = (props) => {
  const { t } = useTranslation(["blogcard", "date"]);
  const addDefaultSrc = (ev) => {
    ev.target.src = "/images/blogdetail/blog-default.jpg";
  };
  return (
    <Link to={`/blog/${props.id}`}>
      <div className="blogcard">
        <Col m={6} s={12} className="container-image">
          <img
            className="responsive-img image-height"
            src={
              !props.image ? "/images/blogdetail/blog-default.jpg" : props.image
            }
            alt="imagenf"
            onError={addDefaultSrc}
          ></img>
        </Col>
        <Col m={6} s={12} className="info">
          <div className="title cut-text">
            <span>{!props.title ? "" : props.title}</span>
          </div>
          <Row className="blog-info">
            <Col s={4} className="date">
              <i className="material-icons calendar_">date_range</i>
              <span className="">
                {t("date:dateP", {
                  day: props.date.getDate(),
                  month: props.date.getMonth(),
                  year: props.date.getFullYear(),
                })}
              </span>
            </Col>
            <Col s={8} className="author">
              {!props.author ? "" : props.author}
            </Col>
          </Row>
          <div className="summary">{!props.summary ? "" : props.summary}</div>
          <div className="continue-reading">
            <span className="continue">{t("continue-reading")}</span>
            <span className="arrow continue">&#8594;</span>
          </div>
        </Col>
      </div>
    </Link>
  );
};
export default Blogcard;
