import React from "react";
import Title from "../Title/Title.js";
import "./card.scss";
import { useTranslation } from "react-i18next";

const Card = ({ infoarray, cname }) => {
  const { t } = useTranslation(["about"]);
  var icon = "";
  if (infoarray.img) {
    icon = (
      <img
        className="logo"
        src={process.env.PUBLIC_URL + infoarray.img}
        alt="LOGO"
      />
    );
  }
  return (
    <div className={cname}>
      {icon}
      <div className="cards">
        <Title title={t(infoarray.title)} />
        <div>
          <p className="texto-info">{t(infoarray.content)}</p>
        </div>
      </div>
    </div>
  );
};
export default Card;
