import React from "react";
import Researcher from "../Researcher/Researcher";
import { Row, Col, Collapsible, CardPanel } from "react-materialize";
import { useTranslation } from "react-i18next";
import Title from "../Title/Title";

const PeopleList = ({ people }) => {
  const { t } = useTranslation(["peoplelist"]);
  return (
    <>
      <Row>
        <Col l={4} m={4} s={12} className="offset-l4 offset-m4">
          <Title title={people.categoryName} />
        </Col>
      </Row>
      <Row>
        <Col l={4} m={8} s={12} className="offset-l4 offset-m2">
          {!people || !people.members || !people.members.length ? (
            <CardPanel className="white">
              <span className="black-text">{t("empty_card")}</span>
            </CardPanel>
          ) : (
            <Collapsible accordion popout>
              {people.members.map((person, item) => (
                <Researcher
                  key={item}
                  name={`${person.names} ${person.lastNames}`}
                  position={person.charge}
                  social={person.socials}
                  img={person.pictureURL}
                  biography={person.description}
                />
              ))}
            </Collapsible>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PeopleList;
