import client from "../index";

export function getblogdetail(id) {
  let options = {
    method: "get",
    url: `/entries/${id}`,
  };

  let api = new client({});

  return api.request(options);
}
