import React, { useState, useEffect } from "react";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";

const GroupsDetails = (props) => {
  //   const [group, setGroup] = useState({});
  const [data, setData] = useState();
  //   const [title, setTitle] = useState();
  useEffect(async () => {
    const data = await fetch(
      `https://notion-api.splitbee.io/v1/page/${props.id}`
    ).then((res) => {
      return res.json();
    });
    // setTitle(data[Object.keys(data)[0]]?.value.properties.title[0][0]);
    setData(data);
  }, [data]);

  if (!data) return <p>Loading ...</p>;
  return (
    <div style={{ paddingBottom: 100, textAlign: "left" }}>
      <NotionRenderer blockMap={data} fullPage />
    </div>
  );
};

export default GroupsDetails;
