import React from "react";
import "./title.scss";

const Title = ({ title }) => {
  const words = title.split(" ");

  if (words.length > 1 && words.length <= 3) {
    words[words.length - 1] = (
      <span key={words.length - 1} className="base-color">
        {" "}
        {words[words.length - 1]}{" "}
      </span>
    );
    for (var j = 0; j < words.length - 1; j++)
      words[j] = <span key={j}> {words[j]} </span>;
  } else if (words.length > 3) {
    for (var i = words.length - 1; i > words.length - 3; i--)
      words[i] = (
        <span key={i} className="base-color">
          {" "}
          {words[i]}{" "}
        </span>
      );
    for (j = words.length - 3; j >= 0; j--)
      words[j] = <span key={j}> {words[j]} </span>;
  }
  const styles = {
    width: title.length / 3 + "em",
  };

  return (
    <div className="titulos">
      <h4>{words}</h4>
      <div className="rectangle" style={styles}></div>
    </div>
  );
};
export default Title;
