import React from "react";
import { Row, Col } from "react-materialize";
import "../Researcher/SocialNetworks/style.css";
import "./footer.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(["footer"]);

  return (
    <div className="footer-color white-text">
      <Row>
        <Col s={12}>
          <h5>COMPSUST</h5>
          <h6>{t("compsust-title")}</h6>
        </Col>
      </Row>
      <Row>
        <Col s={8} m={2} l={2} offset={"s2 m5 l5"}>
          <Col s={4}>
            <a
              href="https://www.linkedin.com/company/compsust/"
              target="_blank"
              rel="noopener noreferrer"
              className="white-text"
            >
              <span className="icon-linkedin footer-icon"></span>
            </a>
          </Col>
          <Col s={4}>
            <a
              href="https://github.com/compsust-utec"
              target="_blank"
              rel="noopener noreferrer"
              className="white-text"
            >
              <span className="icon-github footer-icon"></span>
            </a>
          </Col>
          <Col s={4}>
            <a
              href="https://www.facebook.com/Compsust-UTEC-Centro-de-Investigaci%C3%B3n-en-Computaci%C3%B3n-Sostenible-100520745387184"
              target="_blank"
              rel="noopener noreferrer"
              className="white-text"
            >
              <span className="icon-facebook footer-icon"></span>
            </a>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col s={12}>
          <a
            href="https://goo.gl/maps/LLY1hvQbd9VN8Juw5"
            target="_blank"
            rel="noopener noreferrer"
            className="white-text"
          >
            <span>Jr. Medrano Silva 165, Barranco, Lima - Peru.</span>
          </a>
          <br />
          <span> +51 1 230-5020</span>
          <br />
          <a href="mailto:compsust@utec.edu.pe" className="white-text">
            <span>compsust@utec.edu.pe</span>
          </a>
        </Col>
      </Row>
      <Row className="pastel-color footer-trademark">
        Copyright © {new Date().getFullYear()} UTEC. {t("copyright-text")}
      </Row>
    </div>
  );
};

export default Footer;
