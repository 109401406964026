import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Router } from "@reach/router";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import "materialize-css";
import "materialize-css/dist/css/materialize.css";
import "material-design-icons/iconfont/material-icons.css";
import "./i18n/index";
import LoadingSpinner from "./components/SemipolarLoading";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner />}>
      <Router>
        <App default />
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
