import React from "react";
import "./error404.scss";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-materialize";
import Image from "../../components/Image/Image";

const Error404 = () => {
  const { t } = useTranslation(["error404"]);
  return (
    <div className="Error404">
      <Row>
        <Image
          image={{
            src: "/images/error404/cs.png",
            l: 4,
            m: 4,
            s: 2,
            crow: "offset-s5 offset-m4 offset-l2",
            cname: "responsive-img error-image hide-on-med-and-down",
          }}
        />
        <Col l={5} m={12} s={12} className="error-text offset-l0">
          <p className="error-title">404</p>
          <p className="error-p">{t("error-title")}</p>
          <div className="rectangle-404"></div>
          <p className="error-message">{t("error-content")}</p>
          <Link to="/" className="white-text">
            <Button id="button-error" className="left" waves="light">
              {t("error-button")}
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Error404;
