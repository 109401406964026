import React from "react";
import { Router, Location } from "@reach/router";
import "./app.scss";
import Home from "../../pages/Home/Home";
import Research from "../../pages/Research/Research.js";
import AboutUs from "../../pages/AboutUs/AboutUs";
import Infrastructure from "../../pages/Infrastructure/Infrastructure";
import Blog from "../../pages/Blog/Blog";
import BlogDetail from "../../pages/BlogDetail/BlogDetail";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Error404 from "../../pages/Error404/Error404";
import Group from "../../pages/Groups/Groups";
import GroupsDetails from "../../pages/GroupsDetails/GroupsDetails";

function App() {
  return (
    <div className="App">
      <Location>
        {({ location }) => {
          const body = document.getElementById("main-content");
          if (location.pathname === "/infrastructure") {
            body.classList.add("infrastructure-body");
          } else {
            body.classList.remove("infrastructure-body");
          }
        }}
      </Location>
      <NavBar />
      <Router>
        <Home path="/" />
        <AboutUs path="/about" />
        <Group path="/groups" />
        <Research path="/research" />
        <Infrastructure path="/infrastructure" />
        <Blog path="/blog" />
        <BlogDetail path="/blog/:id" />
        <GroupsDetails path="/groups/:id" />
        <Error404 default />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
