import React, { useEffect, useState } from "react";
import Background from "../../components/Background/Background";
import { Link } from "@reach/router";
import { getblogs } from "../../client/pages/blog";
import { Row, Col, CardPanel, Button } from "react-materialize";
import { useTranslation } from "react-i18next";
import Blogcard from "../../components/Blogcard/Blogcard";
import Title from "../../components/Title/Title";
import "./home.scss";

const Home = () => {
  const { t, i18n } = useTranslation(["home", "infrastructure"]);
  const [showParticles, setShowParticles] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page] = useState(1);
  const [per_page] = useState(2);
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    setLoading(true);
    getblogs(page, per_page)
      .then((blogs) => {
        setEntries(blogs.data.entries);
      })
      .catch(() => {
        setEntries([]);
      });
    setLoading(false);
  }, [i18n.language, page, per_page]);

  useEffect(() => {
    if (!showParticles) {
      setTimeout(() => setShowParticles(true), 100);
    }
  });

  return (
    <div>
      <div className="fluid-container">
        <div id="home-background" className="particles hide-on-med-and-down">
          {showParticles && <Background />}
        </div>
        <Row className="valign-wrapper">
          <Col id="home-brand" s={12} l={12}>
            <Link to="/" className="brand-logo black-text">
              <img
                id="home-brand-logo-img"
                className="responsive-img"
                alt="compsust isotipo"
                src={`${process.env.PUBLIC_URL}/images/logos/logotipoOficial.svg`}
              />
            </Link>
            <h6>{t("compsust-title")}</h6>
          </Col>
        </Row>
      </div>
      <div className="fluid-container">
        <Row className="colored-section white-text flow-text">
          <Col s={12} m={12} l={12} className="hide-on-small-only">
            <img
              id="home-inf-khipu-img"
              alt="server"
              src={`${process.env.PUBLIC_URL}/images/infrastructure/server.svg`}
            />
          </Col>
          <Col s={12} m={8} l={6} offset={"m2 l3"}>
            <h4>{t("infrastructure:page-title")}</h4>
            <p className="inf-text-content center-align">
              {t("infrastructure:khipu-description")}
            </p>
            <Button id="home-inf-call-to-action" waves="light">
              <a href="/infrastructure">{t("infrastructure:call-to-action")}</a>
            </Button>
          </Col>
        </Row>
      </div>
      <div className="container">
        <Row>
          <Col s={12}>
            <Title title={t("our-blog")} />
          </Col>
        </Row>
        <Row>
          <Col s={12} m={10} l={12} className="offset-m1 offset-l2">
            {!entries || entries.length === 0 ? (
              <Col s={12} l={8}>
                <CardPanel className="white no-results">
                  <span className="black-text">{t("no-results")}</span>
                </CardPanel>
              </Col>
            ) : loading ? (
              <h2>{t("loading")}...</h2>
            ) : (
              entries.map((item) => (
                <Col key={item.id} l={8} s={12}>
                  <Blogcard
                    id={item.id}
                    image={item.imageURL}
                    title={item.title}
                    date={new Date(item.publishDate)}
                    author={item.author}
                    summary={item.summary}
                  />
                </Col>
              ))
            )}
          </Col>
        </Row>
        <Row className="center-align home-call-to-action-btn">
          <Button id="inf-call-to-action" waves="light">
            <strong>
              <Link to="/blog" className="white-text">
                {t("see-more-articles")}
              </Link>
            </strong>
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default Home;
