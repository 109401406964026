import React from "react";
import "./infrastructure.scss";
import "../../components/Title/Title.js";
import Title from "../../components/Title/Title.js";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Card } from "react-materialize";

const Infrastructure = () => {
  const { t } = useTranslation(["infrastructure"]);

  return (
    <section>
      <img
        id="server-img"
        className="hide-on-med-and-down"
        alt="server"
        src={`${process.env.PUBLIC_URL}/images/infrastructure/server.svg`}
      />
      <Row>
        <article className="inf-main-content">
          <Title title={t("page-title")}></Title>
          <p className="inf-text-content">{t("khipu-description")}</p>
          <Button id="inf-call-to-action" waves="light">
            <a
              href="https://cs.utec.edu.pe/khipu/"
              className="white-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("call-to-action")}
            </a>
          </Button>
        </article>
      </Row>
      <section className="inf-primary-content section">
        <Row className="">
          <Col s={12} m={6} l={4} offset={"l2"}>
            <Card className="hoverable">
              <i className="material-icons server-icon">storage</i>
              <h5>{t("khipu-card-title-01")}</h5>
              <p className="left-align">{t("khipu-intro")}</p>
            </Card>
            <Card className="hoverable">
              <i className="material-icons server-icon">people</i>
              <h5>{t("khipu-card-title-02")}</h5>
              <p className="left-align">{t("khipu-objective")}</p>
            </Card>
          </Col>
          <Col s={12} m={6} l={4}>
            <a
              href="https://cs.utec.edu.pe/khipu/"
              className="white-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="responsive-img"
                alt="khipu specs"
                src={`${process.env.PUBLIC_URL}/images/infrastructure/khipu-specs.png`}
              />
            </a>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Infrastructure;
