import client from "../index";

export function getMembers() {
  let options = {
    method: "get",
    url: "/members_categories",
  };

  let api = new client({});

  return api.request(options);
}
