import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { Link } from "@reach/router";
import "./navbar.scss";
import { MenuItems } from "./MenuItems";
import { LanguageOptions } from "./LanguageOptions";
import CloseButton from "./CloseButton/CloseButton";
import NavItems from "./NavItems/NavItems";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const location = useLocation();
  const [page, setPage] = useState(location.pathname);
  const { t, i18n } = useTranslation(["navbar"]);
  useEffect(() => {
    const M = window.M;
    var sidenav = document.querySelectorAll("#sidenav-2");
    M.Sidenav.init(sidenav, { edge: "right" });
    var sidenav1 = document.querySelectorAll("#sidenav-1");
    M.Sidenav.init(sidenav1, { edge: "left" });
  }, []);
  useEffect(() => {
    setPage(location.pathname);
  }, [location]);
  const handlePageActive = (page) => {
    setPage(page);
  };

  const handleScroll = function () {
    const nav = document.getElementById("nav");
    const navBrandImage = document.getElementById("navbar-brand-image");

    if (window.scrollY === 0) {
      nav.classList.remove("sticky-nav");
      navBrandImage.classList.add("hide");
    } else if (window.scrollY > 32) {
      nav.classList.add("sticky-nav");
      navBrandImage.classList.remove("hide");
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <nav id="nav" className="z-depth-0 navbar">
        <div className="nav-wrapper">
          <div className="row">
            <div className="col s12">
              <a
                href="#!"
                data-target="sidenav-1"
                className="left sidenav-trigger show-on-medium-and-up"
              >
                <i className="material-icons" id="item">
                  menu
                </i>
              </a>
              <a
                href="#!"
                data-target="sidenav-2"
                className="right sidenav-trigger show-on-medium-and-up"
              >
                <img
                  alt="language selector"
                  className="circle language-image"
                  src={`${process.env.PUBLIC_URL}/images/navbar/${i18n.language}.png`}
                />
              </a>
              <Link
                to="/"
                className="brand-logo center black-text hide-on-med-and-down"
              >
                <img
                  id="navbar-brand-image"
                  className="responsive-img brand-logo-img hide"
                  alt="compsust isotipo"
                  src={`${process.env.PUBLIC_URL}/images/logos/logotipoOficialSinTexto.svg`}
                />
              </Link>
              <NavItems
                items={MenuItems}
                cname="right hide-on-med-and-down"
                cname_li=""
                activePage={page}
                changePage={handlePageActive}
              />
            </div>
          </div>
        </div>
      </nav>

      <NavItems
        items={MenuItems}
        id="sidenav-1"
        cname="sidenav"
        activePage={page}
        changePage={handlePageActive}
        cname_li="left-align sidebar-items"
        closeButton={<CloseButton cname="left-align" />}
      />
      <ul id="sidenav-2" className="sidenav">
        <li>
          <CloseButton cname="left-align" />
        </li>
        {LanguageOptions.map((item, index) => {
          return (
            // eslint-disable-next-line
            <li
              key={index}
              className="left-align sidenav-close"
              onClick={() => {
                i18n.changeLanguage(item.lng);
              }}
            >
              <a href="#!">
                <img
                  alt=""
                  className="circle language-image"
                  src={`${process.env.PUBLIC_URL}/images/navbar/${item.lng}.png`}
                />
                {t(item.title)}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavBar;
