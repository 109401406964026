import React from "react";
import { useTranslation } from "react-i18next";
import "./Groups.scss";
import { Row, Col } from "react-materialize";
import GroupCard from "../../components/GroupCard/GroupCard";

const groups = [
  {
    id: "218ee944432d4856a6e20756a1b8811f",
    // imageURL: `${process.env.PUBLIC_URL}/images/blogdetail/blog1.svg`,
    title: "Computación Científica",
    summary:
      "El grupo de computación científica se dedica a la investigación en el área de la computación científica, con el objetivo de desarrollar herramientas computacionales que permitan resolver problemas de ingeniería y ciencias aplicadas.",
  },
];

const Groups = () => {
  const { t } = useTranslation(["groups", "date"]);

  return (
    <div>
      <div className="blog-cover">
        <Row>
          <Col l={4} className="text-inside-cover offset-l2">
            <p>{t("title-head")}</p>
            <h3>{t("title")}</h3>
            <p>{t("title-low")}</p>
          </Col>
          <Col
            l={3}
            className="img-inside-cover offset-l1 hide-on-med-and-down"
          >
            <img
              className="blog-cover-img"
              src={`${process.env.PUBLIC_URL}/images/groups/groups_header.svg`}
              alt=""
            />
          </Col>
        </Row>
      </div>
      <Row className="blog-section">
        <Col l={5} m={10} s={12} className="offset-l2 offset-m1">
          {groups.map((item) => (
            <GroupCard
              key={item.id}
              id={item.id}
              image={item.imageURL}
              title={item.title}
              date={new Date(item.publishDate)}
              author={item.author}
              summary={item.summary}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Groups;
