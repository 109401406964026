export const LanguageOptions = [
  {
    lng: "es",
    title: "spanish",
  },
  {
    lng: "pt",
    title: "portuguese",
  },
  {
    lng: "en",
    title: "english",
  },
];
