import api from "axios";
import i18n from "i18next";

class Compsust {
  constructor(config) {
    this.authorization = process.env.REACT_APP_AUTHORIZATION;
    this.basePath = process.env.REACT_APP_BASE_PATH;
    this.language = config.language || i18n.language || window.localStorage.i18nextLng || process.env.REACT_APP_DEFAULT_LANGUAGE || "es"; // prettier-ignore
    this.timeout = config.timeout || process.env.REACT_APP_DEFAULT_TIMEOUT || 180000; // prettier-ignore
  }

  request(options = {}) {
    let configOptions = {
      ...options,
      baseUrl: this.basePath,
      timeout: this.timeout,
    };

    let path = this.basePath + options.url;

    let headers = {
      Authorization: this.authorization,
      "Accept-Language": this.language,
      "Content-type": "application/json",
    };

    let config = {
      ...configOptions,
      headers: headers,
    };

    return api(path, config);
  }
}

export default Compsust;
