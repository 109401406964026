import React from "react";
import { Link } from "@reach/router";
import { Translation } from "react-i18next";
import "./navitems.scss";

const NavItems = ({
  items,
  cname,
  cname_li,
  id,
  closeButton,
  activePage,
  changePage,
}) => {
  return (
    <ul id={id} className={cname}>
      {!closeButton ? <div></div> : <li>{closeButton}</li>}
      {items.map((item, index) => {
        return (
          // eslint-disable-next-line
          <li key={index} onClick={() => changePage(item.url)}>
            <Link
              className={
                activePage === item.url
                  ? `${cname_li} active-item sidenav-close`
                  : `${cname_li} navbar-items sidenav-close`
              }
              to={item.url}
            >
              <Translation ns="navbar">
                {(t) => <>{t(item.title)}</>}
              </Translation>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NavItems;
