export const MenuItems = [
  {
    title: "home",
    url: "/",
  },
  {
    title: "aboutUs",
    url: "/about",
  },
  {
    title: "groups",
    url: "/groups",
  },
  {
    title: "infrastructure",
    url: "/infrastructure",
  },
  {
    title: "blog",
    url: "/blog",
  },
];
