import React, { useState, useEffect } from "react";
import PeopleList from "../../components/PeopleList/PeopleList";
import { getMembers } from "../../client/pages/aboutUs";
import Card from "../../components/Card/Card";
import Title from "../../components/Title/Title";
import Image from "../../components/Image/Image";
import { information, values, partners } from "./information";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-materialize";
import "./AboutUs.scss";

const AboutUs = () => {
  const [peopleList, setPeopleState] = useState([]);
  const { t, i18n } = useTranslation(["about"]);

  useEffect(() => {
    getMembers()
      .then((members) => {
        setPeopleState(members.data);
      })
      .catch((err) => console.log(err));
  }, [i18n.language]);
  return (
    <>
      <Row className="row-about">
        <Image
          image={{
            src: "/images/logos/isotipo.svg",
            l: 4,
            m: 4,
            s: 2,
            crow: "offset-s5 offset-m4 offset-l2",
            cname: "responsive-img image-us hide-on-med-and-down",
          }}
        />
        <Col l={4} m={12} s={12} className="us-text offset-l0">
          <Card cname="center-align" infoarray={information[0]} />
        </Col>
      </Row>
      <Row>
        <Col s={12} m={6} l={5} className="offset-l1">
          <Card
            cname="about-card"
            infoarray={{ title: "vision-title", content: "vision-content" }}
          />
        </Col>
        <Col s={12} m={6} l={5}>
          <Card
            cname="about-card"
            infoarray={{ title: "mission-title", content: "mission-content" }}
          />
        </Col>
      </Row>
      <Row className="row-about">
        <Col l={5} m={12} s={12} className="offset-l1">
          <Card cname="about-card" infoarray={information[1]} />
        </Col>
        <Col l={5} m={12} s={12} className="offset-l0 col-images-values">
          {values.map((img, index) => (
            <Image key={index} image={img} />
          ))}
        </Col>
      </Row>
      <Row className="row-about">
        <Col l={4} m={12} s={12} className="offset-l1">
          <Card cname="about-card" infoarray={information[2]} />
        </Col>
        <Col l={5} m={12} s={12} className="offset-l1">
          <div className="about-card">
            <Title title={t(information[3].title)} />
            <ol className="custom-counter">
              <li>{t(information[3].content1)}</li>
              <li>{t(information[3].content2)}</li>
              <li>{t(information[3].content3)}</li>
            </ol>
          </div>
        </Col>
      </Row>
      <Row className="row-about">
        {!peopleList.length ? (
          <PeopleList people={{ categoryName: t("empty-team"), members: [] }} />
        ) : (
          peopleList.map((list, index) => {
            return <PeopleList key={index} people={list} />;
          })
        )}
      </Row>
      <Row className="image-about">
        <Col l={8} m={12} s={12} className="offset-l2">
          <div className="about-card">
            <Title title={t(information[4].title)} />
            <p className="p-about">{t(information[4].content)}</p>
          </div>
        </Col>
        <Col s={12} className="wrapper">
          {partners.map((img, index) => (
            <Image key={index} image={img} />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default AboutUs;
