import client from "../index";

export function getblogs(page, per_page) {
  let options = {
    method: "get",
    url: `/entries/paging?page=${page}&per_page=${per_page}`,
  };

  let api = new client({});

  return api.request(options);
}

export function getpopulartags(n) {
  let options = {
    method: "get",
    url: `/tags/top?num_tags=${n}`,
  };

  let api = new client({});

  return api.request(options);
}
