import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const languages = ["es", "en", "pt"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug:
      process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_DEBUG_I18N === "true",
    fallbackLng: false,
    supportedLngs: languages,
    detection: {
      order: ["localStorage", "querystring", "navigator"],
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      caches: ["localStorage"],
    },
    cache: {
      enabled: true,
    },
  });

export default i18n;
