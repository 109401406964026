import React from "react";
import Card from "../../components/Card/Card";
import information from "./information.js";

const Research = () => {
  return information.map((info, index) => {
    return <Card infoarray={info} key={index} />;
  });
};

export default Research;
